import { postRequest, getRequest, deleteRequest } from '../utils/request'


export default {
    changeVipInfo(status, params) {
        return postRequest(`/api/vipuser/${status}`, params)
    },
    //会员列表
    list(params) {
        const { params: para } = params
        return getRequest('/api/vipuser/list', para)
    },
    getInfoByVcardId(id) {//根据id查询详情
        return getRequest(`/api/vipuser/info/${id}`)
    },
    putCard(params) {
        return postRequest('/api/vipuser/putCard', params)
    },
    editStatusCard(params) {
        return postRequest('/api/vipuser/editStatusCard', params)
    },
    deleteVcardById(id) {
        return deleteRequest(`/api/vipuser/delete/${id}`)
    },
    getAppidByNonce(nonce) {
        return getRequest(`/api/shangy/getAppid/${nonce}`)
    },
    forbiddenVcardById(params) {

        console.log(params)
        return postRequest('/api/vipuser/forbidden', params)
    },
}
