<template>
  <div class="login-container">
    <span>正在跳转洗车,请稍等。。。</span>
    </div>
</template>

<script>
import { getCookie, setCookie } from "../../utils/cookie";
import vip from "../../api/vipuser";
export default {
  data() {
    return {
      appid: "wx24344222ab8e1aeb",
      redirect_uri:"http:///shang.xiaoruizhixi.com/qrcode-boot/api/shangy/RDOpenCard",
    };
  },
  created() {
    let backurl = this.$route.query.backurl;
    // if (this.$route.query.backurl === undefined) {
    //   backurl = document.referrer;
    // }
    // if (backurl !== undefined) {
    //   setCookie("backurl", backurl, 1);
    // }
    // let macno=this.getQueryVaribale(backurl)
    // alert(macno)
    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${
      this.appid
    }&redirect_uri=${encodeURIComponent(
      this.redirect_uri
    )}&response_type=code&scope=snsapi_base&state=${
      backurl
    }#wechat_redirect `;
  },
};
</script>

<style lang="scss" scoped>
.login-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
